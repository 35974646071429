<div class="fieldset">
  <div class="dFlex">
    <label
      class="field-validate__label"
      *ngIf="fieldName"
      [ngStyle]="{
        'font-weight':
          fieldName === 'Nome'
            ? '500'
            : fieldName === 'Enviar a orientação para o WhatsApp do tutor?' ||
                fieldName ===
                  'Enviar a orientação automaticamente para o e-mail do tutor?'
              ? '600'
              : '',
        'margin-bottom.px':
          fieldName === 'Enviar a orientação para o WhatsApp do tutor?' ||
          fieldName ===
            'Enviar a orientação automaticamente para o e-mail do tutor?'
            ? '16'
            : ''
      }"
    >
      {{ fieldName }}
      <span *ngIf="inputRequired && showAsterisk" class="color-red"> * </span>
      <i
        *ngIf="labelInfoTooltipMessage"
        class="bi bi-info-circle"
        (click)="onPressLabelInfoIcon()"
        placement="right"
        [tooltip]="!showMobileLabelInfoModal && labelInfoTooltip"
      ></i>
    </label>

    <div
      *ngIf="showField === undefined || showField === null ? true : showField"
    >
      <div
        class="input-wrapper outlined"
        [ngClass]="isDisabledClass"
        [style.background-color]="bgColor"
      >
        <div class="prefix">
          <ng-content select="[p-prefix]"></ng-content>
        </div>
        <div class="form-field">
          <ng-content select="input"></ng-content>
          <ng-content select="textarea"></ng-content>
          <ng-content select="select"></ng-content>
          <ng-content select="mc-select"></ng-content>
          <ng-content select="[input-customized]"></ng-content>
          <app-generic-loading
            [right]="24"
            [isLoading]="loading"
          ></app-generic-loading>
        </div>
        <div class="suffix">
          <ng-content select="[p-suffix]"></ng-content>
          <!-- <i class="fa fa-times" style="color: red; margin-left:0.5rem;" *ngIf="hasError()"></i> -->
        </div>
      </div>
      <span class="error-message" *ngIf="hasError()">
        {{ customErrorMessage ? customErrorMessage : messageError }}
      </span>
    </div>
  </div>
</div>

<ng-template #labelInfoTooltip>
  <div class="label-info-tooltip">
    <span [innerHTML]="labelInfoTooltipMessage"></span>
  </div>
</ng-template>

<app-mobile-modal-overlay
  (closeModalEmitter)="closeMobileLabelInfoModal()"
  [appOverflowHidden]="showMobileLabelInfoModal"
  *ngIf="showMobileLabelInfoModal"
>
  <div class="pets-data-tooltip">
    <span [innerHTML]="labelInfoTooltipMessage"></span>
    <hr class="w-100" />
  </div>
</app-mobile-modal-overlay>
