import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { ProductService } from 'src/app/services/product/product.service';
import { AppListResponse } from 'src/app/services/shared/app.response';
import { GenericNavDrawerComponent } from '../generic-nav-drawer/generic-nav-drawer.component';
import { ProductAdvancedFilterModel } from 'src/app/services/product/models/product-advanced-filter-model';
import { Product } from 'src/app/services/calculator/models/calculator-product-result-model';

@Component({
  selector: 'app-change-product',
  templateUrl: './change-product.component.html',
  styleUrls: ['./change-product.component.scss']
})
export class ChangeProductComponent implements OnInit {
  @ViewChild(InfiniteScrollDirective)
    infiniteScrollDirective: InfiniteScrollDirective;
  @ViewChild('drawer') navDrawer: GenericNavDrawerComponent;
  @Input() productFilter: ProductAdvancedFilterModel = {};
  @Input() titleProduct: string = '';
  @Input() filterKeyProduct: string;
  @Input() speciePet: number;
  @Output() closeChange = new EventEmitter<boolean>();
  @Output() productSelected = new EventEmitter<Product>();
  products: Product[] = [];
  searchValue: string = '';
  productSearch = new Subject<string>();
  titleProductToChange: string;
  typeProduct: number;
  mobileScreen: boolean;
  screenWidth: number;
  totalFilters: number;
  showProductsTotalTags: boolean = false;

  private itemsPerPage = 10;
  private pageNumber = 0;
  public totalCount = 0;
  constructor(private productService: ProductService) {
    this.getScreenSize();
  }

  ngOnInit(): void {
    this.getProducts();
    this.subscribeSearchProduct();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: number) {
    this.screenWidth = window.innerWidth;
    this.screenWidth > 576
      ? (this.mobileScreen = false)
      : (this.mobileScreen = true);
  }

  getProducts() {
    const productFilter = {
      ...this.productFilter,
      name: this.searchValue.toLocaleLowerCase(),
      pageNumber: this.pageNumber,
      pageSize: this.itemsPerPage
    };
    this.productService.getFilterAdvanced(productFilter).subscribe((res) => {
      this.products = [...this.products, ...res.items];
      this.totalCount = res.totalCount;
      this.showProductsTotalTags = true;
    });
  }

  subscribeSearchProduct() {
    this.productSearch
      .pipe(
        debounceTime(500),
        switchMap(() => {
          this.resetScroll();
          const productFilter = {
            ...this.productFilter,
            name: this.searchValue.toLocaleLowerCase(),
            pageNumber: 0,
            pageSize: this.itemsPerPage
          };
          return this.productService.getFilterAdvanced(productFilter);
        })
      )
      .subscribe((res: AppListResponse<Product>) => {
        this.products = res.items;
        this.pageNumber = 0;
        this.totalCount = res.totalCount;
      });
  }

  resetScroll() {
    this.infiniteScrollDirective.ngOnDestroy();
    this.infiniteScrollDirective.setup();
  }

  selectProduct(product: Product, event: Event) {
    if (this.isLink(event)) {
      return;
    }

    this.productSelected.next(product);
    this.close();
  }

  isLink(event: Event): boolean {
    const target = event.target as Element;
    const parent = target.parentElement as Element;

    if (parent.classList.contains('btn-link')) {
      return true;
    }

    return false;
  }

  onScroll() {
    if (this.products.length >= this.totalCount) {
      return;
    }

    this.pageNumber += 1;
    this.getProducts();
  }

  close() {
    this.closeChange.emit(true);
  }

  openFilter(): void {
    this.typeProduct = this.productFilter.typeProduct;
    this.navDrawer.toggleVisibility();
  }

  filter(filters: ProductAdvancedFilterModel) {
    this.productFilter = {
      ...this.productFilter,
      ...filters
    };
    this.pageNumber = 0;
    this.searchValue = '';
    this.products = [];
    this.getProducts();
    this.getTotalFiltersApplied(filters);
  }

  getTotalFiltersApplied(filters: ProductAdvancedFilterModel): number {
    let total = 0;

    for (const key in filters) {
      total += (filters as any)[key].length;
    }
    return (this.totalFilters = total);
  }
}
