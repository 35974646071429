import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weight'
})
export class WeightPipe implements PipeTransform {
  transform(): string {
    return '';
  }
  public convertWeight(value: string): string {
    value = this.checkCommaPosition(value);
    value = value.replace(',', '.');
    this.checkCommaPosition(value);
    if (!Number(value)) {
      return null;
    }
    const isGrams: boolean = value.substring(0, 1).includes('0');
    const isDecimal: boolean = value.includes('.');
    switch (true) {
    case isDecimal:
      const temp = value.split('.');
      value = temp[0] + ',' + this.autoFillWithZeros(temp[1]);
      return value;
    case value.length === 1 && !isGrams:
      return value + ',000';
    case value.length === 2 && !isGrams:
      return value + ',000';
    case value.length === 3 && !isGrams:
      return value[0] + ',' + value.substring(1) + '0';
    case value.length === 4 && !isGrams:
      return value[0] + ',' + value.substring(1);
    case value.length === 5 && !isGrams:
      return value.substring(0, 2) + ',' + value.substring(2);
    case value.length === 6 && !isGrams:
      return value.substring(0, 2) + ',' + value.substring(2, 5);
    case value[0] === '0':
      value = this.autoFillWithZeros(value.substring(1));
      value = '0' + ',' + value;
      return value;
    default:
      return '0';
    }
  }
  private autoFillWithZeros(value: any): string {
    const standardLength = 3;

    for (let i = value.length; i < standardLength; i++) {
      value += '0';
    }
    return value;
  }
  private checkCommaPosition(value: string): string {
    const position = value.search(',');
    if (position <= 3) {
      return value;
    }
    return value.replace(',', '');
  }
}
