import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EventTrackingEnum } from '../../modules/shared/enums/event-tracking.enum';
import { AuthenticationService } from '../authentication/authentication.service';
import { LoadingService } from '../shared/loading.service';
import { FinalOrientation } from './models/final-orientation.model';
import { OrientationAdvancedFilter } from './models/orientation-advanced-filter.model';
import { OrientationResponse } from './models/orientation.model';
import { TotalFilterOrientation } from './models/total-filters-orientation.model';
import { EventTrackerModel } from './models/event-tracker-model';
import { ObesityTreatmentOrientationModel } from './models/obesity-treatment-orientation.model';

@Injectable({
  providedIn: 'root'
})
export class OrientationService {
  constructor(
    private http: HttpClient,
    private loadingService: LoadingService,
    private authService: AuthenticationService
  ) {}

  getOrientations(
    page: number = 1,
    size: number = 10,
    sortField: string,
    sortOrder = 1,
    startDate?: Date,
    endDate?: Date,
    gender?: number[],
    specie?: number[]
  ): Observable<OrientationResponse> {
    const headers = this.loadingService.getLoadingHeader();

    const filter = {
      Filters: [
        {
          Key: 'VeterinarioId',
          Operator: 'Equals',
          Value: this.authService.getUserId().toString()
        }
      ],
      PageNumber: page,
      PageSize: size,
      SortField: sortField,
      SortOrder: sortOrder
    };

    if (startDate && endDate) {
      filter.Filters.push({
        Key: 'DataPrescricao',
        Operator: 'GreaterThanOrEqual',
        Value: startDate.toISOString().substring(0, 10)
      });

      filter.Filters.push({
        Key: 'DataPrescricao',
        Operator: 'LessThanOrEqual',
        Value: endDate.toISOString().substring(0, 10)
      });
    }

    if (gender?.length === 1) {
      filter.Filters.push({
        Key: 'Pet.Genero',
        Operator: 'Equals',
        Value: gender[0].toString()
      });
    }

    if (specie?.length === 1) {
      filter.Filters.push({
        Key: 'Pet.PetEspecie',
        Operator: 'Equals',
        Value: specie[0].toString() === '1' ? 'dog' : 'cat'
      });
    }

    return this.http.post<OrientationResponse>(
      `${environment.baseApimUrl}/prescricao/list`,
      filter,
      {
        headers
      }
    );
  }

  getOrientationById(orientationCode: string): Observable<FinalOrientation> {
    const headers = this.loadingService.getLoadingHeader();
    return this.http.get<FinalOrientation>(
      `${environment.baseApimUrl}/orientationByCode/${orientationCode}`,
      { headers }
    );
  }

  getOrientationsSearch(
    filters: OrientationAdvancedFilter,
    loadingActive = true
  ): Observable<OrientationResponse> {
    const headers = loadingActive
      ? this.loadingService.getLoadingHeader()
      : new HttpHeaders();

    const filter = {
      veterinarianId: this.authService.getUserId(),
      ...filters
    };

    return this.http.post<OrientationResponse>(
      `${environment.baseApimUrl}/prescricao/advanced/list`,
      filter,
      { headers }
    );
  }

  getTotalOrientationAdvanced(): Observable<TotalFilterOrientation> {
    const veterinarianId = this.authService.getUserId();
    const url = `${environment.baseApimUrl}/prescricao/${veterinarianId}/advanced/totals`;

    return this.http.get<TotalFilterOrientation>(url);
  }

  downloadOrientation(
    orientationCode: string,
    email: string,
    whatsappLink?: boolean
  ): Promise<Blob> {
    const headers = this.loadingService.getLoadingHeader();
    let url = `${environment.baseApimUrl}/orientationPdfByCode/${orientationCode}/${(new Date().getTime() / 1000).toString()}/${email}`;
    if (whatsappLink) {
      url += `?trackingCode=${EventTrackingEnum.DOWNLOAD_ORIENTATION_FROM_WHATSAPP}`;
    }
    return this.http
      .get<Blob>(url, {
        responseType: 'blob' as 'json',
        headers
      })
      .toPromise();
  }

  getOrientationLink(
    orientationCode: string,
    email: string,
    phone: string,
    petName: string,
    whatsappLink?: boolean
  ): string {
    let url = `${
      environment.urlPvet
    }baixar-orientacao/${orientationCode}/${email.trim()}/${phone}/${petName}`.replace(
      / /g,
      '%20'
    );
    if (whatsappLink) {
      url += '?whatsapp=true';
    }

    return url;
  }

  createWhatsappMessage(
    tutorName: string,
    petName: string,
    vetName: string,
    link: string
  ): string {
    const tutorNames = tutorName.split(' ');
    const tutorFirstName = tutorName.length > 0 ? tutorNames[0] : tutorNames;
    return `Olá ${tutorFirstName}.\n\nVocê acabou de receber a orientação personalizada do seu pet ${petName}, prescrita pelo Dr.(a) ${vetName}.\n\nPara baixar, é só clicar no link abaixo:\n\n${link}`;
  }

  addEventTracker(model: EventTrackerModel) {
    const url = `${environment.baseApimUrl}/prescricao/addeventtracker`;
    return this.http.post<boolean>(url, model);
  }

  getOrientationObesityTreatment(
    petId: number
  ): Observable<ObesityTreatmentOrientationModel> {
    const url = `${environment.baseApimUrl}/orientation/pet/${petId}/obesity-treatment `;
    return this.http.get<ObesityTreatmentOrientationModel>(url);
  }
}
