import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Input()
    showContact = true;
  imgLogo = '../../../../../assets/images/premiervet-logo.png';
  urlPartnerProgam: string = environment.urlPartnerProgram;
  urlTermsPdf: string = environment.urlTermsPdf;
  year: number = new Date().getFullYear();
  constructor() {}
}
