<form class="form" [formGroup]="registrationForm">
  <h1 class="form-title">Personalize seu PremieRvet<sup>&reg;</sup></h1>
  <ng-container *ngIf="!isMobile; else mobileTemplate">
    <div class="form-flex gap-0 mb-4">
      <button
        type="button"
        class="select-button veterinarian"
        [class.selected]="!isStudent"
        (click)="selectVeterinarian()"
      >
        Sou médico-veterinário
      </button>
      <button
        type="button"
        class="select-button student"
        [class.selected]="isStudent"
        (click)="selectStudent()"
      >
        Sou estudante
      </button>
    </div>

    <div *ngIf="!isStudent">
      <div>
        <field-validate [showAsterisk]="false" fieldName="Nome Completo">
          <input
            type="text"
            id="name"
            onlyText
            formControlName="name"
            placeholder="Informe o seu nome"
            maxlength="90"
          />
        </field-validate>
      </div>
      <div>
        <field-validate [showAsterisk]="false" fieldName="CPF">
          <input
            #cpf
            type="text"
            id="cpf"
            formControlName="cpf"
            mask="000.000.000-00"
            placeholder="000.000.000-00"
            [nextField]="crmv"
          />
        </field-validate>
      </div>
      <div class="form-flex">
        <field-validate [showAsterisk]="false" fieldName="CRMV">
          <input
            #crmv
            numbersOnly
            type="text"
            id="crmvNumber"
            formControlName="crmvNumber"
            placeholder="00000"
            maxlength="5"
            minlength="4"
          />
        </field-validate>
        <field-validate
          [showAsterisk]="false"
          fieldName="UF"
          for="state-crmv"
          class="uf__field"
        >
          <select id="state-crmv" placeholder="MG" formControlName="state">
            <option selected [value]="null">Selecione</option>
            <option *ngFor="let state of listStates" [value]="state.name">
              {{ state.name }}
            </option>
          </select>
        </field-validate>
      </div>
    </div>

    <div *ngIf="isStudent">
      <div>
        <field-validate [showAsterisk]="false" fieldName="Nome Completo">
          <input
            type="text"
            id="name"
            onlyText
            formControlName="name"
            placeholder="Informe o seu nome"
            maxlength="90"
          />
        </field-validate>
      </div>
      <div>
        <field-validate [showAsterisk]="false" fieldName="CPF">
          <input
            #cpf
            type="text"
            id="cpf"
            formControlName="cpf"
            mask="000.000.000-00"
            placeholder="000.000.000-00"
          />
        </field-validate>
      </div>
      <div>
        <div class="infinite-select">
          <university-select
            (UniversitySelectedChange)="onUniversitySelected($event)"
          />
        </div>
      </div>
      <p class="form-sub-t">Ano e mês previsto de formação</p>
      <div class="form-flex">
        <field-validate
          [showAsterisk]="false"
          for="graduationYear"
          class="uf__field"
        >
          <select
            id="graduationYear"
            placeholder="Selecione"
            formControlName="graduationYear"
          >
            <option selected [value]="null">Selecione</option>
            <option *ngFor="let year of listYears" [value]="year.value">
              {{ year.label }}
            </option>
          </select>
        </field-validate>
        <field-validate
          [showAsterisk]="false"
          for="graduationMonth"
          class="uf__field"
        >
          <select
            id="graduationMonth"
            placeholder="Selecione"
            formControlName="graduationMonth"
            [disabled]="!registrationForm.get('graduationYear')?.value"
          >
            <option selected [value]="null">Selecione</option>
            <option *ngFor="let month of listMonths" [value]="month.value">
              {{ month.label }}
            </option>
          </select>
        </field-validate>
      </div>
    </div>

    <button
      id="id-register-btn-submit"
      type="submit"
      class="btn btn-primary"
      (click)="submit()"
    >
      <span>Começar</span>
      <i class="bi bi-check-circle"></i>
    </button>
  </ng-container>

  <ng-template #mobileTemplate>
    <div *ngIf="isOptionSelected === null">
      <div class="mobile-select" [class.selected]="!isStudent">
        <label>
          <input
            type="radio"
            name="studentStatus"
            [(ngModel)]="isStudentSelectedValue"
            [ngModelOptions]="{ standalone: true }"
            [value]="false"
          />
          Sou médico-veterinário
        </label>
      </div>

      <div class="mobile-select" [class.selected]="isStudent">
        <label>
          <input
            type="radio"
            name="studentStatus"
            [(ngModel)]="isStudentSelectedValue"
            [ngModelOptions]="{ standalone: true }"
            [value]="true"
          />
          Sou estudante
        </label>
      </div>
    </div>

    <ng-container
      *ngIf="isOptionSelected !== null && isOptionSelected && !isStudent"
    >
      <div>
        <field-validate [showAsterisk]="false" fieldName="Nome Completo">
          <input
            type="text"
            id="name"
            onlyText
            formControlName="name"
            placeholder="Informe o seu nome"
            maxlength="90"
            [nextField]="cpf"
          />
        </field-validate>
      </div>
      <div>
        <field-validate [showAsterisk]="false" fieldName="CPF">
          <input
            #cpf
            type="text"
            id="cpf"
            formControlName="cpf"
            mask="000.000.000-00"
            placeholder="000.000.000-00"
            [nextField]="crmv"
          />
        </field-validate>
      </div>

      <div>
        <field-validate [showAsterisk]="false" fieldName="CRMV">
          <input
            #crmv
            numbersOnly
            type="text"
            id="crmvNumber"
            formControlName="crmvNumber"
            placeholder="00000"
            maxlength="5"
            minlength="4"
          />
        </field-validate>
      </div>

      <div>
        <field-validate
          [showAsterisk]="false"
          fieldName="UF"
          for="state-crmv"
          class="uf__field"
        >
          <select id="state-crmv" placeholder="MG" formControlName="state">
            <option selected [value]="null">Selecione</option>
            <option *ngFor="let state of listStates" [value]="state.name">
              {{ state.name }}
            </option>
          </select>
        </field-validate>
      </div>
    </ng-container>

    <ng-container
      *ngIf="isOptionSelected !== null && isOptionSelected && isStudent"
    >
      <div>
        <field-validate [showAsterisk]="false" fieldName="Nome Completo">
          <input
            type="text"
            id="name"
            onlyText
            formControlName="name"
            placeholder="Informe o seu nome"
            maxlength="90"
            [nextField]="cpf"
          />
        </field-validate>
      </div>
      <div>
        <field-validate [showAsterisk]="false" fieldName="CPF">
          <input
            #cpf
            type="text"
            id="cpf"
            formControlName="cpf"
            mask="000.000.000-00"
            placeholder="000.000.000-00"
          />
        </field-validate>
      </div>
      <div class="infinite-select">
        <university-select
          (UniversitySelectedChange)="onUniversitySelected($event)"
        />
      </div>
      <p class="form-sub-t">Ano e mês previsto de formação</p>
      <div class="form-flex">
        <field-validate
          [showAsterisk]="false"
          for="graduationYear"
          class="uf__field"
        >
          <select
            id="graduationYear"
            placeholder="Selecione"
            formControlName="graduationYear"
          >
            <option selected [value]="null">Selecione</option>
            <option *ngFor="let year of listYears" [value]="year.value">
              {{ year.label }}
            </option>
          </select>
        </field-validate>
        <field-validate
          [showAsterisk]="false"
          for="graduationMonth"
          class="uf__field"
        >
          <select
            id="graduationMonth"
            placeholder="Selecione"
            formControlName="graduationMonth"
            [disabled]="!registrationForm.get('graduationYear')?.value"
          >
            <option selected [value]="null">Selecione</option>
            <option *ngFor="let month of listMonths" [value]="month.value">
              {{ month.label }}
            </option>
          </select>
        </field-validate>
      </div>
    </ng-container>

    <button
      *ngIf="isOptionSelected === null && isMobile"
      type="submit"
      class="btn btn-primary"
      (click)="continue()"
    >
      <span>Continuar</span>
    </button>

    <button
      *ngIf="isOptionSelected"
      type="submit"
      class="btn btn-primary"
      (click)="submit()"
    >
      <span>Começar</span>
      <i
        *ngIf="isOptionSelected !== null && isOptionSelected"
        class="bi bi-check-circle"
      ></i>
    </button>
  </ng-template>
</form>
