import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filter-orientation-mobile',
  templateUrl: './filter-orientation-mobile.component.html',
  styleUrls: ['./filter-orientation-mobile.component.scss']
})
export class FilterOrientationMobileComponent implements OnInit {
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() genderChange: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() specieChange: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() clearFilter = new EventEmitter();
  @Input() gender: number[];
  @Input() specie: number[];
  modalOpen: boolean = true;
  checkboxes: CheckboxValues[] = [
    {
      title: 'Cão',
      value: '1',
      selected: false,
      type: 'specie'
    },
    {
      title: 'Gato',
      value: '2',
      selected: false,
      type: 'specie'
    },
    {
      title: 'Femêa',
      value: '2',
      selected: false,
      type: 'gender'
    },
    {
      title: 'Macho',
      value: '1',
      selected: false,
      type: 'gender'
    }
  ];
  constructor() {}

  ngOnInit(): void {
    this.selectedValues();
    this.scrollBodyLocky();
  }

  onCloseModal(): void {
    this.modalOpen = false;
    this.scrollBodyLocky();
    this.closeModal.emit();
  }
  selectedValues(): void {
    this.checkboxes.map((x) => {
      if (x.type === 'gender') {
        x.selected =
          this.gender.find((y) => y.toString().includes(x.value.toString())) !=
          null;
      }
      if (x.type === 'specie') {
        x.selected =
          this.specie.find((y) => y.toString().includes(x.value.toString())) !=
          null;
      }
    });
  }

  getSpecies(value: any): void {
    if (value.target.checked) {
      this.specie.push(value.target.value);
    } else {
      const index = this.specie.indexOf(value.target.value);
      this.specie.splice(index, 1);
    }
    this.specieChange.emit(this.specie);
  }

  getGenders(value: any): void {
    if (value.target.checked) {
      this.gender.push(value.target.value);
    } else {
      const index = this.gender.indexOf(value.target.value);
      this.gender.splice(index, 1);
    }
    this.genderChange.emit(this.gender);
  }

  scrollBodyLocky() {
    this.modalOpen
      ? ((document.body.style.overflowY = 'hidden'),
        (document.body.style.position = 'fixed'))
      : ((document.body.style.overflowY = 'auto'),
        (document.body.style.position = 'inherit'));
  }

  removeFilters(): void {
    this.checkboxes.forEach((c) => (c.selected = false));
    this.gender = [];
    this.specie = [];
    this.clearFilter.next(null);
  }

  get countFiltersActive(): number {
    return this.checkboxes.filter((c) => c.selected)?.length;
  }
}

class CheckboxValues {
  title: string;
  value: string | number;
  selected: boolean;
  type: string;
}
